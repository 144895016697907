import React from "react"
import Layout from "../components/App/Layout"
import {graphql} from "gatsby";
import ReactMarkdown from "react-markdown"
import PageBanner from "../components/Common/PageBanner";
import Footer from "../components/App/Footer";
import Navbar from "../components/App/Navbar";

const EssentialTemplate = ({data}) => {
    const {title, content} = data.legal

    return (
        <Layout>

            <Navbar />
            <PageBanner
                pageTitle={data.legal.title}
                homePageText="Home"
                homePageUrl="/"
                activePageText={title}
            />
            <section className="blog-details-area ptb-100">
                <div className="container">
                        <div className={'content-section section'}>
                            <ReactMarkdown children={content} />
                        </div>
                    </div>
            </section>
            <Footer />
        </Layout>
    )
}

export const query = graphql`
query GetSingleLegal($slug: String) {
  legal: strapiLegal(slug: { eq: $slug })  {
      slug
      title
      content

      }
  }

`

export default EssentialTemplate
